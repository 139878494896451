<script lang="ts">
  import Checkbox from "./Checkbox.svelte"
  import Honeypot from "./Honeypot.svelte"
  import Input from "./Input.svelte"

  let infoEmail: string
  let invalidEmail: boolean = false
  let infoLegal: string
  let invalidLegal: boolean = false
  let responseMessage: string
  let successState: boolean = false

  async function submit(e: SubmitEvent) {
    e.preventDefault()
    const formData = new FormData(e.currentTarget as HTMLFormElement)
    const response = await fetch("/api/feedbackNewsletter", {
      method: "POST",
      body: formData,
    })
    const data = await response.json()
    infoEmail = data.infoEmail
    invalidEmail = data.invalidEmail
    infoLegal = data.infoLegal
    invalidLegal = data.invalidLegal
    responseMessage = data.message
    successState = data.success
  }
</script>

<div class={$$restProps.class || "w-full flex flex-col gap-5 lg:gap-10"}>
  <div class="text-white flex flex-col gap-5 max-w-3xl">
    <h2 class="text-heading-lg uppercase text-green">Stay Updated</h2>
    <p class="text-copy">Subscribe to our newsletter for updates, news, and exclusive insights.</p>
  </div>
  <form on:submit={submit} class="max-w-3xl flex w-full flex-col items-center lg:items-start justify-start gap-5 lg:gap-10 {successState ? 'hidden' : ''}" novalidate>
    <div class="grid w-full grid-cols-1 gap-x-10 gap-y-5 lg:gap-x-20 lg:gap-y-10 items-center">
      <Input type="email" name="email" req={true} invalidity={invalidEmail}>
        <span slot="label">E-Mail</span>
        {#if infoEmail}
          <p>{infoEmail}</p>
        {/if}
      </Input>
      <Honeypot />
    </div>
    <Checkbox name="legal" invalidity={invalidLegal}>
      <span slot="label">
        I have read and understood the <a href="/privacy" target="_blank" class="font-medium underline hover:text-green">privacy policy</a>
        .
      </span>
      {#if infoLegal}
        {infoLegal}
      {/if}
    </Checkbox>
    <button
      type="submit"
      class="text-button w-full appearance-none border-4 border-solid px-4 py-2 text-center uppercase hover:animate-throb focus:outline-none focus:ring-2 focus:ring-opacity-75 lg:w-auto border-green bg-green text-black focus:ring-green"
    >
      Submit
    </button>
  </form>
  {#if responseMessage}
    <p class="text-white text-copy">{responseMessage}</p>
  {/if}
</div>
